<template>
<div style="max-width: 1400px; margin: 0 auto;">
    <v-stepper v-model="ui.step" vertical>
        <v-stepper-step step="1" :complete="ui.stepStates[1]">
            Step #1: Patient Sign-Up
        </v-stepper-step>

            <v-stepper-content step="1">
                <v-card
                    color="grey lighten-1"
                    class="mb-12"
                    height="200px"
                >
                </v-card>
                <v-btn
                    color="primary"
                    @click="ui.stepStates[1]=true; ui.step++"
                >
                    Continue
                </v-btn>
                <v-btn v-if="ui.step>1" @click="ui.step--">
                    Back
                </v-btn>
            </v-stepper-content>

        <v-stepper-step step="2" :complete="ui.stepStates[2]">
            Step #2: Patient Assessment
        </v-stepper-step>

            <v-stepper-content step="2">
                <Survey :questionSetID="questionSetID" :debug="debug" :questionSet="questionSet" @submit="submit"/>
                <v-btn
                    color="primary"
                    @click="ui.step++"
                >
                    Continue
                </v-btn>
                <v-btn v-if="ui.step>1" @click="ui.step--">
                    Back
                </v-btn>
            </v-stepper-content>

        <v-stepper-step step="3" :complete="ui.stepStates[3]">
            Step #3: Set Follow-up date
        </v-stepper-step>

            <v-stepper-content step="3">
                Follow-up component
                <v-btn
                    color="primary"
                    @click="ui.step++"
                >
                    Continue
                </v-btn>
                <v-btn v-if="ui.step>1" @click="ui.step--">
                    Back
                </v-btn>
            </v-stepper-content>

    </v-stepper>
</div>
</template>

<script>
import cache from '@/plugins/cache.js'
import Survey from '@/components/Questions/QuestionSet'

export default {
    name: "PatientAssessment",
    components: {
        Survey
    },
    created: function(){
        let key = this.questionSetID
        let storage = window.localStorage;
        let cache = storage.getItem('survey_editor_cache');
        if(cache){
            cache = JSON.parse(cache);
            if(cache[key]){
                this.questionSet = cache[key]
            }
        }
        this.init()

    },
    data: function(){
    return {
        state: 'confirm_access',
        config: {
            states: ['confirm_access','allowed','not_allowed','loading','ready','completed']
        },
        questionSetID: 'PatientAssessment',
        questionSet: null,
        form: {
            survey: {}
        },
        ui: {
            step: 1,
            stepStates: {
                1: false,
                2: false,
                3: false
            }
        },
        PatientAssessments: []
    }
    },
    methods: {
        init: function(){
            this.getPatientAssessments()
        },
        submit: function(data){
            function save(user_id, data){
                let PatientAssessments = cache.storage.local.get('PatientAssessments') ? cache.storage.local.get('PatientAssessments') : {}
                if(!PatientAssessments[user_id]){
                    PatientAssessments[user_id] = []
                }
                PatientAssessments[user_id].push(data)
                cache.storage.local.set('PatientAssessments',PatientAssessments)
            }

            this.form.survey = data
            let PatientAssessment = {
                answers: data,
                followUp: null
            }
            save(this.$store.getters.userID, PatientAssessment)
            this.ui.stepStates[2] = true
            this.ui.step++
        },
        getPatientAssessments: function(){
            this.PatientAssessments = cache.storage.local.get('PatientAssessments') ? cache.storage.local.get('PatientAssessments')[this.$store.getters.userID] : []
        }
    },
    computed: {
        debug: function(){
            return this.$store.getters.debug
        },
        language: function(){
            return this.$store.getters.language
        }
    },
    watch: {}

}
</script>

<style scoped>


</style>
